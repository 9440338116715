<template>
  <v-card v-if="tempSettings != null" flat tile class="px-0">
    <v-row>
      <v-col>
        <v-row no-gutters>
          <v-select style="max-width: 8rem;" :label="texts.de.rateRounding" v-model="tempSettings.rateRounding.value"
            :items="rateRoundingValues" item-value="value" item-text="name" @change="emit" />
          <v-select class="ml-4" style="max-width: 9rem;" v-model="tempSettings.rateRounding.type"
            :items="rateRoundingTypes" item-value="value" item-text="name" @change="emit" />
        </v-row>
      </v-col>
      <v-col>
        <v-row no-gutters>
          <v-text-field style="max-width: 7rem;" :value="tempSettings.countryKey"
            :label="$t('condAdmin.provider.countryKey')" disabled />
          <v-text-field class="ml-4" style="max-width: 5rem;" :value="tempSettings.currency" :label="$t('base.currency')"
            disabled />
          <EditField
            class="ml-4"  style="max-width: 7rem;"
            :value="tempSettings.vat"
            :label="$t('base.vat')"
            hideDetails
            disabled
            type="ufloat"
            suffix="%"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters>
          <v-checkbox
            class="pt-2 mb-8"
            v-model="showCustomCalcType"
            :label="texts.de.showCustomCalcType"
            @change="setShowCustomCalcType"
          ></v-checkbox>
          <v-text-field v-if="showCustomCalcType === true" class="pt-0 ml-4" style="max-width: 10rem;" v-model="tempSettings.customCalcType"
            :placeholder="texts.de.customCalcType"  @change="emit" />
    </v-row>
  </v-card>
</template>

<script lang="js">
import EditField from '@/common/EditField';

export default {
  name: 'ProviderSettingsDaag',
  components: {
    EditField
  },
  props: {
    settings: { type: Object, required: true }
  },
  data () {
    return {
      showCustomCalcType: false,
      tempSettings: null,
      texts: {
        de: {
          rateRounding: 'Rundung der Raten',
          floor: 'abgerundet',
          ceil: 'aufgerundet',
          standard: 'kaufmännich',
          showCustomCalcType: 'Anbieterspezifische Kalkulationsvariante nutzen',
          customCalcType: 'Kennzeichen'
        }
      }
    };
  },
  computed: {
    rateRoundingValues () {
      return [
        { value: 0.01, name: '0,01 ' + this.tempSettings.currency },
        { value: 0.05, name: '0,05 ' + this.tempSettings.currency },
        { value: 0.10, name: '0,10 ' + this.tempSettings.currency }
      ];
    },
    rateRoundingTypes () {
      return [
        { value: '', name: this.texts.de.standard },
        { value: 'floor', name: this.texts.de.floor },
        { value: 'ceil', name: this.texts.de.ceil }
      ];
    }
  },
  watch: {
    settings () {
      this.init();
    }
  },
  mounted () {
    this.init();
    this.showCustomCalcType = (this.tempSettings.customCalcType != null && this.tempSettings.customCalcType.length > 0);
  },
  methods: {
    init () {
      this.tempSettings = JSON.parse(JSON.stringify(this.settings));
      if (this.tempSettings.rateRounding == null) {
        this.tempSettings.rateRounding = { value: 0.01, type: '' };
      }
    },
    emit () {
      this.$emit('update', this.tempSettings);
    },
    setShowCustomCalcType (value) {
      this.showCustomCalcType = value;
      if (value === false) {
        this.tempSettings.customCalcType = '';
      }
      this.emit();
    }
  }
};
</script>

<style scoped>
</style>
