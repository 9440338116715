import { render, staticRenderFns } from "./ProviderSettings_daag.vue?vue&type=template&id=12306584&scoped=true"
import script from "./ProviderSettings_daag.vue?vue&type=script&lang=js"
export * from "./ProviderSettings_daag.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12306584",
  null
  
)

export default component.exports